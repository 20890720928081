import gql from "graphql-tag";

export const LOGIN_HISTORY_LIST = gql`
    query LoginHistory($first: Int, $after: String){
        loginHistories(first: $first, after: $after) {
            pageInfo {
                hasNextPage,
                hasPreviousPage
                endCursor
                startCursor
            }
            edges {
                node {
                    _id
                    onDate
                    user {
                        name
                    }
                    companyHeadquarter {
                        name
                    }
                }
            }
        }
    }
`