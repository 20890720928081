<template>
  <div>
    <base-page-heading title="Istoric de autentificare">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Istoric de autentificare</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <base-block title="Lista istoricului de autentificare">
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 70%;">Istoric</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 20%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 40%;">Firma</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 40%;">Utilizator</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="history.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="3" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3 text-center">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="item in history" :key="item.node._id">
              <b-td class="d-sm-none" style="width: 75%;">
                <b-row>
                  <b-col class="col-4 mt-2 text-left mt-1">
                    <div><i class="fa fa-calendar-alt"></i> <span class="text-dark font-weight-bolder">{{ getDateStr(item.node.onDate) }}</span></div>
                  </b-col>
                  <b-col class="col-8 mt-2 text-left mt-1">
                    <div><span class="text-primary">{{ item.node.companyHeadquarter.name }}</span></div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                    <div><span class="text-primary">{{ item.node.user.name }}</span></div>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ getDateStr(item.node.onDate) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-primary">{{ item.node.companyHeadquarter.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-info">{{ item.node.user.name }}</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import {ITEM_NUMBER_PER_PAGE} from "@/constants/settings";
import {fleshErrorMessage} from "@/error/server-error";
import {LOGIN_HISTORY_LIST} from "@/constants/login-history-graphql";
import InfiniteLoading from "vue-infinite-loading";
import {formatDate} from "@/constants/helpers/common-helper";

export default {
  name: "LoginHistoryList",
  components: {InfiniteLoading},
  data () {
    return {
      history: [],
      pageInfo: null,
      loaded: false,
    }
  },
  methods: {
    loadMore($state) {
      this.$apollo.query({
        query: LOGIN_HISTORY_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.loginHistories.pageInfo;
        this.history.push(...result.data.loginHistories.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDateStr(value) {
      return formatDate(new Date(value));
    },
  }
}
</script>